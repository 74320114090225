import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';
import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';

export default function AboutMasterclass() {
  const { getAboutMasterclass } = useMasterclassMetadata();
  const aboutMasterclass = getAboutMasterclass();

  return (
    <MasterclassSectionLayout
      className="container grid grid-cols-1 pt-[26px] md:pt-[46px] lg:grid-cols-[1.4fr_1fr]"
      heading={'About Masterclass'}
    >
      <RawHTML className="font-rubik text-[8px] leading-[14px] text-v5-neutral-600 md:text-[18px] md:leading-[28px]">
        {aboutMasterclass}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
