import axios from 'axios';

const webinarApi = {
  url: 'https://63934u7gji.execute-api.ap-south-1.amazonaws.com/register',
};

class WebinarJamService {
  register = async (payload) => {
    console.log('🚀 ~ WebinarJamService ~ register= ~ payload:', payload);
    await axios({
      method: 'post',
      url: webinarApi.url,
      data: payload,
    });
  };
}

export default new WebinarJamService();
