import { Button } from '@mui/material';
import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';
import { Link } from 'gatsby';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function EventCard({ event }) {
  const { getEventDetails } = useMasterclassMetadata();

  const {
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
  } = getEventDetails(event.attributes);

  return (
    <div className="max-w-[180px] flex-1 rounded-[15px] bg-[#D9D9D9] shadow-[0px_3.72px_3.53px_0px_#00000026] md:max-w-[300px]">
      <div className="h-[115px] rounded-t-[15px]"></div>

      <div className="flex flex-col gap-y-[12px] rounded-[15px] bg-white p-[14px] md:gap-y-6 md:p-6">
        <div className="flex flex-col gap-[3px] md:gap-[6px]">
          <div className=" font-manrope text-[12px] font-bold leading-[12px] text-v5-green-700 md:text-[22px] md:leading-[24px]">
            {sessionName}
          </div>
          <div className="mt-1 font-rubik text-[8px] leading-[14px] text-v5-neutral-500 md:text-xs">
            <span className="font-medium">Date :</span>
            <span className="font-normal">{sessionDate}</span>
            &nbsp;
            <span className="font-medium">Time : </span>
            <span className="font-normal">{sessionTime}</span>
          </div>
        </div>

        <div className="text-v5-neutral-50 flex flex-col gap-[2px] leading-[13px] md:gap-2 ">
          <div className="font-manrope text-[8px] font-semibold md:text-[16px]">
            {sessionHostName}
          </div>
          <div className="font-rubik text-[6px] font-normal md:text-[11px]">
            {sessionHostDesignation}
          </div>
        </div>

        <div className="flex items-center justify-between gap-2">
          <div className="font-rubik text-[6px] font-normal leading-[8px] text-v5-neutral-500 md:text-xs md:leading-[16px]">
            {isSeatTypeHyped ? (
              <span>
                <span className="font-medium">{sessionSeats}</span> People have
                registered
              </span>
            ) : (
              <span>
                Filling Fast:{' '}
                <span className="font-medium">{sessionSeats} left</span>
              </span>
            )}
          </div>
          <Link to={`/masterclass/register/${event?.attributes?.eventSlug}`}>
            <Button
              className={twMerge(
                `natural sm flex items-center whitespace-nowrap rounded-[4px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-2 px-[7px] font-manrope text-[8px] font-bold capitalize leading-[14px] text-black hover:bg-v5-yellow-100 md:rounded-[10px] md:py-3 md:px-[14px] md:text-[14px] `,
              )}
              type="contained"
            >
              Register Now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
