import React from 'react';
import { cn } from '@/lib/utils';

function SectionHeading({
  heading,
  doesSectionHaveBackground,
  isHeadingWithoutBackgroundOnMobile,
}) {
  return (
    <div
      className={cn(
        'rounded-[4px] bg-v5-neutral-200 px-[12px] font-manrope text-[18px] font-bold leading-normal md:rounded-[10px] md:text-[40px] md:leading-[18px]',
        doesSectionHaveBackground
          ? 'pt-[11px] md:pt-9 md:pb-6'
          : 'py-4 shadow-[0px_4px_9.4px_0px_#00000014] md:px-6 md:py-9',
        isHeadingWithoutBackgroundOnMobile
          ? 'bg-white text-center text-[24px] shadow-none md:bg-v5-neutral-200 md:py-[36px] md:text-left md:text-[40px] md:leading-[18px] md:shadow-[0px_4px_9.4px_0px_#00000014]'
          : '',
      )}
    >
      {heading}
    </div>
  );
}

export default SectionHeading;
