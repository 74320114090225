import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';
import EventCard from '../EventCard';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useResizer from '@components/extra/useResizer';

export default function UpcomingEvents() {
  const { getUpcomingEvents } = useMasterclassMetadata();
  const isMobile = useResizer();
  const isLessThan1024 = useResizer(1024);
  const isLessThan1200 = useResizer(1200);
  const upcomingEvents = getUpcomingEvents() || [];

  return upcomingEvents.length > 0 ? (
    <MasterclassSectionLayout
      className="container relative -top-12 grid grid-cols-1 md:static md:pt-[32px] md:pb-[100px] lg:grid-cols-[58%_1fr] xl:grid-cols-[58%_1fr]"
      heading={'Upcoming Events'}
      isHeadingWithoutBackgroundOnMobile={true}
    >
      <Swiper
        grabCursor={true}
        spaceBetween={isMobile ? 20 : 0}
        slidesPerView={
          isLessThan1024 ? (isMobile ? 1.5 : 2) : isLessThan1200 ? 1.5 : 2
        }
        className="w-full p-2 lg:max-w-[650px]"
        pagination={{ el: '.swiper-pagination', clickable: true }}
        modules={[Pagination, Navigation]}
      >
        {upcomingEvents.slice(0, 2).map((event, index) => (
          <SwiperSlide key={index} className="w-[180px] md:w-[330px]">
            <EventCard event={event} />
          </SwiperSlide>
        ))}
      </Swiper>
    </MasterclassSectionLayout>
  ) : null;
}
