import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';
import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';

export default function MasterclassOutcomes() {
  const { getMasterclassOutcomes } = useMasterclassMetadata();
  const sessionOutcomes = getMasterclassOutcomes();

  return (
    <MasterclassSectionLayout
      className="container grid grid-cols-1 pt-[30px] md:pt-[60px] lg:grid-cols-[1.4fr_1fr]"
      heading={'Masterclass Outcomes'}
      doesSectionHaveListItems={true}
    >
      <RawHTML className="font-rubik text-[8px] leading-[14px] text-v5-neutral-600 md:text-[18px] md:leading-[28px]">
        {sessionOutcomes}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
