import useResizer from '@components/extra/useResizer';
import React from 'react';

/**
 * SocialShareIcon component
 *
 * This component renders a social share icon that opens a share URL in a new window.
 * It displays different icons for mobile and desktop views based on the screen size.
 *
 * Props:
 * - Icon: The icon component to display on desktop.
 * - MobileIcon: The icon component to display on mobile.
 * - label: The label to display below the icon.
 * - shareUrl: The base URL to use for sharing.
 * - className: Additional classes for the container div.
 * - iconClassName: Additional classes for the icon.
 * - labelClassName: Additional classes for the label.
 *
 *  * @example
 * // Desktop-only icon
 * <SocialShareIcon
 *   Icon={TwitterIcon}
 *   label="Twitter"
 *   shareUrl="https://twitter.com/intent/tweet?text="
 * />
 *
 * * @example
 * // Mobile-only icon
 * <SocialShareIcon
 *   MobileIcon={WhatsAppMobileIcon}
 *   label="WhatsApp"
 *   shareUrl="https://wa.me/?text="
 * />
 */

const SocialShareIcon = ({
  Icon,
  MobileIcon,
  label,
  shareUrl,
  contentToShare,
  className = '',
  iconClassName = '',
  labelClassName = '',
}) => {
  const isMobile = useResizer(); // Determine if the screen size is mobile

  /**
   * If the icon component is not provided for the current viewport, return null
   * For eg: If the viewport is mobile and the mobile icon is not provided, return null
   * If the viewport is desktop and the desktop icon is not provided, return null
   */
  if ((isMobile && !MobileIcon) || (!isMobile && !Icon)) {
    return null;
  }

  /**
   * Opens a new window/tab with the sharing URL
   * Encodes the current page URL and appends it to the sharing base URL
   */
  const handleShare = () => {
    window.open(`${shareUrl}${encodeURIComponent(contentToShare)}`, '_blank');
  };

  return (
    <div
      className={`flex flex-col items-center justify-between gap-3 ${className}`}
    >
      {/* Conditionally render either mobile or desktop icon based on viewport */}
      {isMobile ? (
        <MobileIcon
          className={`block cursor-pointer md:hidden ${iconClassName}`}
          onClick={handleShare}
        />
      ) : (
        <Icon
          className={`hidden cursor-pointer md:block ${iconClassName}`}
          onClick={handleShare}
        />
      )}
      {/* Label below the icon */}
      <span
        className={`font-manrope text-[8px] font-bold leading-none text-v5-green-700 ${labelClassName}`}
      >
        {label}
      </span>
    </div>
  );
};

export default SocialShareIcon;
