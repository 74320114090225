import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';
import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';

export default function AboutCrioDo() {
  const { getAboutCrioDo, getAboutCrioImg } = useMasterclassMetadata();
  const aboutCrioDo = getAboutCrioDo();
  const aboutCrioImg = getAboutCrioImg();
  return (
    <MasterclassSectionLayout
      className="container relative -top-12 grid grid-cols-1 pt-[30px] md:static lg:grid-cols-[1.4fr_1fr]"
      heading={'About Crio.Do'}
      sectionImage={aboutCrioImg}
    >
      <RawHTML className="font-rubik text-[10px] leading-[16px] md:text-[18px] md:leading-[28px]">
        {aboutCrioDo}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
