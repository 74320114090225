import {
  calculateSessionEndTime,
  generateMeta,
  generateMetaDescription,
  generateScript,
  generateTitle,
} from '@src/utils/Masterclass/seo';
import useMasterclassMetadata from './useMasterclassMetadata';

export default function useMasterclassSeo() {
  // Destructure necessary functions from useMasterclassMetadata hook
  const {
    getEventDetails,
    getCurrentProgram,
    getEventCardSessionImageForSeo,
    getEventSlug,
  } = useMasterclassMetadata();

  // Function to generate SEO data for the registration page
  const getRegisterationPageSeo = () => {
    // Get event details
    const {
      sessionName,
      isPastEvent,
      sessionHostName,
      sessionStartDateTime,
      createdAt,
    } = getEventDetails();

    // Get additional metadata
    const program = getCurrentProgram();
    const eventSlug = getEventSlug();
    const eventCardSessionImage = getEventCardSessionImageForSeo();
    const sessionEndTime = calculateSessionEndTime(sessionStartDateTime);

    // Generate SEO data
    return {
      title: generateTitle(sessionName, isPastEvent),
      metaDescription: generateMetaDescription(
        sessionName,
        program,
        isPastEvent,
      ),
      meta: generateMeta(eventCardSessionImage, eventSlug),
      script: generateScript(
        sessionName,
        isPastEvent,
        program,
        sessionStartDateTime,
        sessionEndTime,
        eventSlug,
        eventCardSessionImage,
        createdAt,
        sessionHostName,
      ),
      canonicalUrl: `https://www.crio.do/masterclass/register/${eventSlug}`,
    };
  };

  return { getRegisterationPageSeo };
}
