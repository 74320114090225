import SEO from '@components/Common/SEO';
import FooterV2 from '@components/FooterV2';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import AboutCrioDo from '@components/Masterclass/AboutCrioDo';
import AboutMasterclass from '@components/Masterclass/AboutMasterclass';
import CrioFeedback from '@components/Masterclass/CrioFeedback';
import EventDetails from '@components/Masterclass/EventDetails';
import MasterclassHeroSection from '@components/Masterclass/HeroSection';
import HostInfo from '@components/Masterclass/HostInfo';
import MasterclassOutcomes from '@components/Masterclass/MasterclassOutcomes';
import UpcomingEvents from '@components/Masterclass/UpcomingEvents';
import NavigationV2 from '@components/NavigationV2';
import ScrollButtonComponent from '@components/ScrollButtonComponent';
import { LEAD_GEN_FLOATING_CTA } from '@src/constants/LeadGenerationConstants';
import useMasterclassSeo from '@src/hooks/Masterclass/useMasterclassSeo';
import React from 'react';

export default function CrioMasterclassRegisterPage(props) {
  const pageData = props.pageContext?.pageData;
  const { getRegisterationPageSeo } = useMasterclassSeo();

  const seoData = getRegisterationPageSeo(pageData);

  return (
    <>
      <SEO
        title={seoData?.title}
        description={seoData.metaDescription}
        meta={seoData.meta}
        canonicalUrl={seoData.canonicalUrl}
      >
        <script type="application/ld+json">{seoData.script}</script>
      </SEO>
      <div className="relative">
        <ScrollButtonComponent
          component={
            <LeadGenerationButton
              type="Register"
              text="Register Now"
              onClick={() => {
                document.body.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              buttonLocation={LEAD_GEN_FLOATING_CTA}
            />
          }
          startAt={600}
        />
        <NavigationV2 hideCTA hideTopStrip />
        <MasterclassHeroSection />
        <EventDetails />
        <AboutMasterclass />
        <MasterclassOutcomes />
        <HostInfo />
        <CrioFeedback />
        <AboutCrioDo />
        <UpcomingEvents />
      </div>
      <FooterV2 />
    </>
  );
}
