import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useDialog } from '@components/v5/Dialog/index';
import LeadGenerationFormWebView from './LeadGenerationFormWebView';
import LeadGenerationFormComponent from './LeadGenerationFormComponent';
import useResizer from '@components/extra/useResizer';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import CrioButton from '../v5/CrioButton/index';
import { twMerge } from 'tailwind-merge';
import {
  LEAD_GEN_UTM_SOURCE_QA,
  LEAD_GEN_UTM_SOURCE_DEV,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
} from '../../constants/LeadGenerationConstants/index';
import { useLocation } from '@reach/router';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import FormContextProvider from '@src/context/FormContextProvider';

// Button for lead-generation form, conditionally renders mobile and web view based on view-port height
export default function LeadGenerationOpenForm({
  type,
  className,
  wrapperClassName,
  wrapperStyle,
  openFormClassName,
  buttonLocation,
  text,
  programType,
  shouldOpenLeadForm,
  onClick,
  isOnePageForm,
  onSubmitPageOne,
  onSubmitPageTwo,
  pageOneButtonText,
  pageTwoButtonText,
  applicationPage,
  formHeading,
  clickEvent,
  buttonComponent,
  isFullStackPpcVl,
  hasProgramIntrestedField,
  customUtmParams,
}) {
  const isMobile = useResizer();
  const [openDialog, closeDialog] = useDialog();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const dispatch = useContext(GlobalDispatchContext);

  const location = useLocation();
  const pathName = location?.pathname;

  // Dispatch action that lead gen form is open
  const setLeadFormOpenStatusToClosed = () => {
    document.documentElement.style.removeProperty('overflow-y');
    dispatch({
      type: 'SET_IS_LEAD_GEN_FORM_OPEN',
      payload: false,
    });
  };

  // callback when dialog is closed from mobile view
  const handleMobileDialogClose = () => {
    document.documentElement.style.removeProperty('overflow-y');
    setLeadFormOpenStatusToClosed();
    setDialogOpen(false);
  };

  // callback when dialog is closed from web view
  const handleWebDialogClose = () => {
    document.documentElement.style.removeProperty('overflow-y');
    setLeadFormOpenStatusToClosed();
    closeDialog();
  };

  // Click handler wrapper for both mobile and web view
  const onButtonClick = () => {
    if (clickEvent) clickEvent();
    if (onClick) {
      onClick();
    } else {
      document.documentElement.style.setProperty('overflow-y', 'hidden');

      dispatch({
        type: 'SET_IS_LEAD_GEN_FORM_OPEN',
        payload: true,
      });

      // Open mobile/web view of form based on isMobile prop
      !isMobile
        ? openDialog({
            transparentBackground: true,
            closeCallback: setLeadFormOpenStatusToClosed,
            children: (
              <LeadGenerationFormWebView
                closeDialog={handleWebDialogClose}
                buttonLocation={buttonLocation}
                programType={programType}
                isOnePageForm={isOnePageForm}
                onSubmitPageOne={onSubmitPageOne}
                pageOneButtonText={pageOneButtonText}
                pageTwoButtonText={pageTwoButtonText}
                applicationPage={applicationPage}
                formHeading={formHeading}
                isFullStackPpcVl={isFullStackPpcVl}
                customUtmParams={customUtmParams}
              />
            ),
          })
        : setDialogOpen(true);

      if (!isOnePageForm) {
        // Send a GTM event for form open event
        GTM.track(gtmEvents.LEAD_GEN_FORM_OPEN, {
          location:
            pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
            pathName === LEAD_GEN_AUTOMATION_EDGE_PATH
              ? LEAD_GEN_UTM_SOURCE_QA
              : LEAD_GEN_UTM_SOURCE_DEV,
          pathName: pathName,
          buttonLocation: buttonLocation,
        });
      }
    }
  };

  // if shouldOpenLeadForm param is passed; pre-set form's visibility to open
  useEffect(() => {
    if (shouldOpenLeadForm) onButtonClick();
  }, []);

  // Renders button UI based on type prop passed down
  const renderButton = () => {
    if (buttonComponent)
      return React.cloneElement(buttonComponent, { onClick: onButtonClick });
    switch (type) {
      case 'Affiliate':
        return (
          <Button
            disableElevation
            variant="contained"
            onClick={onButtonClick}
            className="h-full  whitespace-nowrap bg-v5-green-600 p-3 font-bold capitalize text-white"
          >
            {text}
          </Button>
        );
      case 'Download':
        return (
          <CrioButton
            variant="secondary"
            className={`w-[300px] sm:w-auto`}
            onClick={onButtonClick}
          >
            {text}
          </CrioButton>
        );
      case 'Apply & Start Free':
        return (
          <CrioButton
            variant="primary"
            onClick={onButtonClick}
            className={twMerge(`h-[64px] px-6 ${className ? className : ''}`)}
          >
            <div className="pr-2 text-left">
              <h6 className="font-extrabold">Apply & Start for free</h6>
              {/* <p className="font-rubik text-xs font-light normal-case">
                First week of learning is free
              </p> */}
            </div>
          </CrioButton>
        );
      case 'Navigation':
        return (
          <Button
            onClick={onButtonClick}
            className={twMerge(
              `natural sm flex w-full items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-2 px-4  font-manrope text-base font-bold capitalize text-black hover:bg-v5-yellow-100 ${
                className ? className : ''
              }`,
            )}
            type="contained"
          >
            Book Your Trial, Now
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-lg"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );
      default:
        return (
          <Button
            onClick={onButtonClick}
            className={twMerge(
              `natural sm flex h-[56px] w-full items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-4 px-8 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-v5-yellow-200/50 hover:shadow-xl  hover:shadow-v5-yellow-100/50 ${
                className ? className : ''
              }`,
            )}
            type="contained"
          >
            {text || 'Book Your Trial, Now'}
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-lg"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );
    }
  };

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <FormContextProvider>
        <LeadGenerationFormComponent
          closeDialog={closeDialog}
          programType={programType}
          mobile={false}
          buttonLocation={buttonLocation}
          openFormClassName={openFormClassName}
          isOnePageForm={isOnePageForm}
          onSubmitPageOne={onSubmitPageOne}
          onSubmitPageTwo={onSubmitPageTwo}
          pageOneButtonText={pageOneButtonText}
          pageTwoButtonText={pageTwoButtonText}
          applicationPage={applicationPage}
          formHeading={'Apply & Start For Free'}
          isOpenForm={true}
          isFullStackPpcVl={isFullStackPpcVl}
          customUtmParams={customUtmParams}
          hasProgramIntrestedField={hasProgramIntrestedField}
        />
      </FormContextProvider>
    </div>
  );
}
