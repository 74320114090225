import masterClassData from '@src/data/masterclass_db.json';
import { useLocation } from '@reach/router';
import { getImage } from 'gatsby-plugin-image';
import {
  filterImagesByEventSlug,
  filterMasterclassImages,
  findAboutCrioImg,
  findBannerImage,
  findEventCardSessionImage,
  findSessionHostImage,
  getCurrentMasterclassEventDetail,
  sanitizeEventDetails,
} from '@src/utils/Masterclass';
import useGraphqlImage from './useGraphqlImage';
import useResizer from '@components/extra/useResizer';

export default function useMasterclassMetadata() {
  const location = useLocation();
  const isMobile = useResizer();
  const pathName = location?.pathname;

  const masterClassDetails = masterClassData?.strapi_metadata;
  const currentMasterclassEventDetail = getCurrentMasterclassEventDetail(
    masterClassDetails?.masterClassEvents,
    pathName,
  );
  const masterClassCommonDetails =
    masterClassData?.strapi_metadata?.masterClassDetails?.[0]?.attributes;

  const eventSlug = currentMasterclassEventDetail?.eventSlug;

  const { masterclassEventsImgNode } = useGraphqlImage();
  const filteredEventImages = filterImagesByEventSlug(
    masterclassEventsImgNode.Thumbnail.nodes,
    eventSlug,
  );

  const filteredMasterclassImages = filterMasterclassImages(
    masterclassEventsImgNode?.Thumbnail?.nodes,
  );

  const getBannerImage = () => {
    const bannerImage = findBannerImage(filteredEventImages, isMobile);
    return getImage(bannerImage?.childImageSharp);
  };

  const getBannerImgSrc = () => {
    const bannerImage = findBannerImage(filteredEventImages, isMobile);
    return bannerImage?.childImageSharp.gatsbyImageData.images.fallback.src;
  };

  const getSessionHostImage = () => {
    const sessionHostImg = findSessionHostImage(filteredEventImages);
    return getImage(sessionHostImg?.childImageSharp);
  };

  const getEventDetails = (event) => {
    return sanitizeEventDetails(event ? event : currentMasterclassEventDetail);
  };

  const getAboutMasterclass = () => {
    return currentMasterclassEventDetail?.sessionDetails?.aboutSession;
  };

  const getMasterclassOutcomes = () => {
    return currentMasterclassEventDetail?.sessionDetails?.sessionOutcomes;
  };

  const getSessionHostInfo = () => {
    return currentMasterclassEventDetail?.sessionDetails?.sessionHost;
  };

  const getAboutCrioDo = () => {
    return masterClassCommonDetails?.aboutCrio;
  };

  const getAboutCrioImg = () => {
    const aboutCrioImg = findAboutCrioImg(filteredMasterclassImages);
    return getImage(aboutCrioImg?.childImageSharp);
  };

  const getCurrentProgram = () => {
    return currentMasterclassEventDetail?.program;
  };

  const getUpcomingEvents = () => {
    const currentEventProgramType = currentMasterclassEventDetail?.program;
    const upcomingEvents = masterClassDetails?.masterClassEvents;

    return upcomingEvents;
  };

  const getCurrentEventWebinarId = () => {
    return currentMasterclassEventDetail?.everWebinarDetails?.webinarjamId;
  };

  const getEventCardSessionImage = () => {
    const eventCardSessionImage =
      findEventCardSessionImage(filteredEventImages);
    return eventCardSessionImage;
  };

  const getEventCardSessionImageForSeo = () => {
    const eventCardSessionImage =
      findEventCardSessionImage(filteredEventImages);
    return eventCardSessionImage?.relativePath.split('/').pop();
  };

  const getEventSlug = () => {
    return eventSlug;
  };

  return {
    masterClassDetails,
    currentMasterclassEventDetail,
    getBannerImage,
    getBannerImgSrc,
    getSessionHostImage,
    getEventDetails,
    getAboutMasterclass,
    getMasterclassOutcomes,
    getSessionHostInfo,
    getAboutCrioDo,
    getAboutCrioImg,
    getUpcomingEvents,
    getCurrentEventWebinarId,
    getCurrentProgram,
    getEventCardSessionImage,
    getEventCardSessionImageForSeo,
    getEventSlug,
  };
}
