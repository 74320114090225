import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';
import React, { useContext } from 'react';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { GatsbyImage } from 'gatsby-plugin-image';
import WebinarJamService from '../../../../utils/webinarjam-service';
import { getCountryCodeAndPhone } from '@src/utils/helper';

export default function MasterclassHeroSection() {
  const { getBannerImage, getCurrentEventWebinarId } = useMasterclassMetadata();

  const state = useContext(GlobalStateContext);

  const onSubmitPageTwo = async (payload) => {
    const webinarjamId = getCurrentEventWebinarId();

    try {
      await WebinarJamService.register({
        firstName: payload.name.split(' ')?.[0] ?? '',
        lastName: payload.name.split(' ')?.[1] ?? '',
        email: state.email,
        webinarId: webinarjamId,
        phoneCountryCode: getCountryCodeAndPhone(state.phone)?.countryCode,
        phone: getCountryCodeAndPhone(state.phone)?.phoneNum,
      });
    } catch (error) {
      console.error('Error registering for webinar', error);
    }
  };

  return (
    <div className={`w-screen pt-20 md:pt-8`}>
      <div className="shadow-[-1px_23px_25.9px_-21px_#0000001A] md:pt-8 ">
        <GatsbyImage
          image={getBannerImage()}
          alt="Crio Masterclass"
          className="h-full w-full"
        />
        {/* Desktop Lead Gen Open Form: Scrollable and sticky */}
        <div className="absolute right-[8%] top-0 hidden h-[calc(100%-160px)] w-screen justify-end lg:flex">
          <LeadGenerationOpenForm
            isOpenForm={true}
            pageTwoButtonText="Register Now"
            applicationPage="masterclass"
            onSubmitPageTwo={onSubmitPageTwo}
            openFormClassName={'md:w-[30vw]'}
            wrapperClassName={'lg:sticky top-24 h-[450px]'}
          />
        </div>
      </div>

      {/* Mobile view lead gen open form: */}
      <LeadGenerationOpenForm
        isOpenForm={true}
        pageTwoButtonText="Register Now"
        applicationPage="masterclass"
        onSubmitPageTwo={onSubmitPageTwo}
        wrapperClassName={`lg:hidden container pt-9`}
        openFormClassName={'mx-auto'}
      />
    </div>
  );
}
