import { useDialog } from '@components/v5/Dialog';
import Layout from '@components/v5/layouts/Layout';
import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMasterclassMetadata from '@src/hooks/Masterclass/useMasterclassMetadata';
import React from 'react';
import ShareDialog from '../ShareDialog';

export default function EventDetails() {
  const { getEventDetails } = useMasterclassMetadata();
  const eventDetails = getEventDetails();
  const [openDialog, closeDialog] = useDialog();

  const {
    sessionName,
    isPastEvent,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionHostDesignation,
  } = eventDetails || {};
  return (
    <Layout className="container relative z-[999] grid grid-cols-1 pt-[36px] md:pt-[44px] lg:grid-cols-[1.4fr_1fr]">
      <div className="flex items-center justify-between gap-5">
        <div className="flex flex-1 flex-col justify-between gap-3 md:flex-row md:items-center md:gap-6">
          {/* Event Title and Date Details */}
          <div className="flex-1">
            <div className="mb-3 break-words break-all font-manrope text-[18px] font-bold leading-6 text-v5-green-700 md:mb-3 md:text-2xl md:leading-[30px]">
              {sessionName}
            </div>
            <div className="font-rubik text-[10px] leading-[14px] text-v5-neutral-500 md:text-lg">
              <span className="font-medium">
                {isPastEvent ? 'Held On :' : 'Date :'}
              </span>
              <span className="font-normal">{sessionDate}</span>
              &nbsp;
              <span className="font-medium">Time : </span>
              <span className="font-normal">{sessionTime}</span>
            </div>
          </div>

          {/* Host Details */}
          <div className="flex flex-1 items-center gap-8 md:ml-3">
            {/* Divider for web view only */}
            <div className="hidden h-20 w-0.5 bg-v5-neutral-300 md:block"></div>

            {/* Event Host Details */}
            <div className="flex flex-row items-center gap-1 text-v5-neutral-500 md:flex-1 md:flex-col md:items-stretch  md:gap-5">
              <div className="flex-1 font-manrope text-xs font-semibold leading-[12px] md:text-2xl md:leading-[24px]">
                {sessionHostName}
              </div>
              <div className="mt-0.5 font-rubik text-[8px] leading-[6px] md:mt-auto md:flex-1 md:text-lg md:leading-[14px]">
                {sessionHostDesignation}
              </div>
            </div>
          </div>
        </div>

        {/* Share Event Button */}
        <div
          className="flex cursor-pointer flex-col items-center gap-3"
          onClick={() => {
            console.log('Share Event');
            openDialog({
              children: <ShareDialog closeDialog={closeDialog} />,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faArrowUpFromBracket}
            className="text-[16px] md:text-[30px]"
          />
          <div className="block font-rubik text-[8px] leading-[6px] text-v5-green-700 md:hidden">
            Share Event
          </div>
        </div>
      </div>
    </Layout>
  );
}
